<template>
	<div class="wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<v-col
				cols="12"
				class="pa-0 px-5 terms"
				align="center"
			>
				<v-col
					cols="12"
					class="pa-0"
					align="center"
				>
					<h2 class="content_title">이용약관</h2>
				</v-col>

				<v-col
					cols="12"
					md="11"
					lg="10"
					align="start"
					class="pa-0"
				>
					<h3>제 1 장 총 칙</h3>
					<div class="mb-8">
						<h4 class="mt-4 mb-2">제1조(목적)</h4>
						<p>
							이 약관은 회원이 동화기업㈜ 건장재사업부(이하 "회사")에서 제공하는 서비스를 이용함에 있어서 회원과
							회사간의 권리, 의무 및 책임사항 기타 필요한 사항에 대하여 규정함을 목적으로 합니다.
						</p>

						<h4 class="mt-4 mb-2">제2조(약관의 효력 및 변경)</h4>
						<ul class="pl-0">
							<li>
								이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공시하고, 이에 동의한 회원이
								서비스에 가입함으로써 효력을 발생합니다.
							</li>
							<li>
								회사는 합리적인 사유가 발생할 경우 관계 법령에 위배되지 않는 범위 내에서 본 약관을 변경할 수
								있으며, 개정된 약관은 사이트에 공지함으로써 효력이 발생합니다. 다만, 이용자의 권리 또는 의무에
								관한 중요한 규정의 변경은 최소한 7일전에 공시합니다.
							</li>
							<li>회원은 변경된 약관 사항에 동의하지 않으면 회원 탈퇴(해지)를 요청할 수 있습니다.</li>
						</ul>

						<h4 class="mt-4 mb-2">제3조 (약관 외 준칙)</h4>
						<p>
							본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 『전자상거래 등에서의 소비자보호에 관한
							법률』, 『약관의 규제에 관한 법률』, 공정거래위원회가 정하는 『전자상거래 등에서의 소비자보호 지침』
							등 관련법령 및 상관례에 따릅니다.
						</p>

						<h4 class="mt-4 mb-2">제4조 (용어의 정의)</h4>
						<ul class="pl-0">
							<li>
								이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
								<ol class="my-1 pl-0">
									<li>
										회원 : 사이트에 접속하여 이 약관에 동의하고, ID(고유번호)와 Password(비밀번호)를 부여 받은
										자를 말합니다.
									</li>
									<li>
										ID(고유번호) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는
										영문자와 숫자의 조합으로, 하나의 주민등록번호에 하나의 ID만 발급, 이용 가능
									</li>
									<li>PASSWORD(비밀번호) : 회원의 정보 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합</li>
									<li>운영자 : 서비스의 전반적인 관리와 원활한 운영을 위하여 회사가 선정한 자</li>
									<li>
										서비스 중지 : 정상 이용 중 회사가 정한 일정한 요건에 따라 일정기간 동안 서비스의 제공을
										중지하는 것
									</li>
								</ol>
							</li>
							<li>
								이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령 및 서비스별 안내에서
								정하는 바에 의합니다.
							</li>
						</ul>
					</div>

					<h3>제 2 장 서비스 이용 계약</h3>
					<div class="mb-8">
						<h4 class="mt-4 mb-2">제5조 (서비스 이용 계약의 성립)</h4>
						<ul class="pl-0">
							<li>
								"위의 이용약관에 동의하십니까?" 라는 이용 신청시의 물음에 고객이 "동의" 버튼을 누르면 약관에
								동의하는 것으로 간주됩니다.
							</li>
							<li>
								회원에 가입하여 서비스를 이용하고자 하는 이용자는 회사에서 요청하는 개인 신상정보를 제공해야
								합니다.
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제6조 (이용 신청)</h4>
						<p>
							이용 신청은 서비스의 이용자 등록 화면에서 고객이 다음 사항을 가입신청 양식에 기록하는 방식으로
							행합니다.
						</p>
						<ol>
							<li>이름</li>
							<li>ID(고유번호)</li>
							<li>Password(비밀번호)</li>
							<li>핸드폰번호</li>
							<li>E-mail(전자우편) 주소</li>
							<li>기타 회사가 필요하다고 인정하는 사항</li>
						</ol>

						<h4 class="mt-4 mb-2">제7조 (이용신청의 승낙)</h4>
						<p>
							회사는 제6조에서 정한 사항을 정확히 기재하여 이용 신청한 고객에 대하여 서비스 이용 신청을 승낙합니다.
						</p>

						<h4 class="mt-4 mb-2">제8조 (이용신청에 대한 승낙의 제한)</h4>
						<ul class="pl-0">
							<li>
								회사는 다음 각 호에 해당하는 신청에 대하여는 이를 승낙하지 않거나, 승낙을 취소할 수 있습니다.
								<ol class="pl-0">
									<li>기술상 서비스 제공이 불가능한 경우</li>
									<li>실명이 아니거나, 다른 사람의 명의사용 등 이용자 등록 시 허위로 신청하는 경우</li>
									<li>이용자 등록 사항을 누락하거나 오기하여 신청하는 경우</li>
									<li>
										기타 관계 법령 등을 기준으로 사회의 안녕과 질서 또는 미풍양속을 저해할 우려가 있다고
										인전되는 경우
									</li>
									<li>
										이전에 회원 자격을 상실한 적이 있는 경우. 다만, 동 자격 상실 이후 1년 이상 경과한 자로
										회사의 회원 재가입 승낙을 받은 경우는 예외로 합니다.
									</li>
									<li>기타 회사가 정한 이용신청 요건이 만족되지 않았을 경우</li>
								</ol>
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제9조 (계약 사항의 변경)</h4>
						<p>
							회원은 이용 신청 시 기재한 사항이 변경되었을 경우 회사가 정한 별도의 이용 방법으로 정해진 양식 및
							방법에 의하여 수정하여야 합니다.
						</p>
					</div>

					<h3>제 3 장 서비스 이용</h3>
					<div class="mb-8">
						<h4 class="mt-4 mb-2">제10조 (서비스의 이용시간)</h4>
						<ul class="pl-0">
							<li>
								서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로
								합니다.
							</li>
							<li>
								전항의 서비스 이용시간은 시스템 정기점검 등 회사가 필요한 경우, 이용자에게 사전 통지한 후, 제한할
								수 있습니다. 다만, 회사가 통제할 수 없는 사유로 인해 서비스가 중단되는 경우에는 사전 통지 없이
								서비스 이용이 제한될 수 있습니다.
							</li>
							<li>
								회사는 서비스를 일정범위로 분할하여 각 범위별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우
								그 내용을 공지합니다.
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제11조 (정보의 제공 및 광고의 게재)</h4>
						<ul class="pl-0">
							<li>
								회사는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 SMS , 알림톡 발송, e-mail 및
								서신우편 등의 방법으로 회원에게 제공할 수 있습니다.
							</li>
							<li>
								회사는 서비스의 운영과 관련하여 홈페이지, 서비스 화면, SMS, 알림톡, e-mail등에 광고 등을 게재할 수
								있습니다.
							</li>
							<li>
								회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의
								방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과 광고주간에
								문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도
								지지 않습니다.
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제12조 (게시물 또는 내용물의 삭제)</h4>
						<ul class="pl-0">
							<li>
								회사는 회원이 게시하거나 전달하는 서비스 내의 모든 내용물(회원간 전달 포함)이 다음 각 호의 경우에
								해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있으며, 이에 대해 회사는 어떠한 책임도 지지
								않습니다.
								<ol class="pl-0">
									<li>회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</li>
									<li>공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등의 유포에 해당하는 경우</li>
									<li>범죄적 행위에 결부된다고 인정되는 내용인 경우</li>
									<li>회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
									<li>제2항 소정의 세부이용지침을 통하여 회사에서 규정한 게시기간을 초과한 경우</li>
									<li>회사에서 제공하는 서비스와 관련 없는 내용인 경우</li>
									<li>불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우</li>
									<li>기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는 경우</li>
								</ol>
							</li>
							<li>
								회사는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 각 종
								게시물(회원간 전달 포함)을 등록하거나 삭제하여야 합니다.
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제13조 (게시물의 저작권)</h4>
						<ul class="pl-0">
							<li>
								회원이 서비스 내에 게시한 게시물(회원간 전달 포함)의 저작권은 회원이 소유하며 회사는 서비스 내에
								이를 게시할 수 있는 권리를 갖습니다. 회원이 다음 각 호에 해당한다고 판단되는 게시물을 등록하였을
								경우 부적절한 게시물로 판단하고 문제가 되는 내용을 부분수정, 삭제, 게시 보류 또는 게시를 중단할 수
								있습니다.
								<ol class="pl-0">
									<li>제3자를 비난하거나 명예를 훼손시키는 경우</li>
									<li>서비스의 취지와 무관한 경우</li>
									<li>타인의 저작권, 상표권 등을 침해하는 경우</li>
									<li>음란성 콘텐츠와 혐오성 게시글</li>
									<li>특정인의 개인정보가 포함된 경우</li>
									<li>회사가 허용하지 아니한 영리활동과 관련된 게시글</li>
									<li>게시물에 첨부된 사진, 정보, 글이 부적절하거나 충분한 정보를 제공하지 않는 경우</li>
									<li>기타 관련법령 및 회사 서비스 별 개별약관 등에 위반된다고 판단되는 경우</li>
								</ol>
							</li>
							<li>
								회원이 회사 서비스에서 탈퇴하는 경우, 회사는 회원이 작성한 게시물을 별도의 동의 없이 삭제할 수
								있습니다. 또한 회사는 해당 게시물을 삭제하지 않고 회사 내에 그대로 게시할 수 있습니다.
							</li>
							<li>
								회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권,프로그램 저작권 등을 침해하더라도 이에
								대한 민,형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권, 프로그램저작권 등을
								침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 회사의 면책을
								위하여 노력하여야 하며, 회사가 면책되지 못한 경우 회원은 그로 인해 회사에 발생한 모든 손해를
								부담하여야 합니다.
							</li>
							<li>
								회사는 회원이 해지하거나 적법한 사유로 해지 된 경우 해당 회원이 게시하였던 게시물을 삭제할 수
								있습니다.
							</li>
							<li>회사가 작성한 저작물에 대한 저작권은 회사에 귀속합니다.</li>
							<li>
								회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로
								이용하거나 제3자에게 이용하게 할 수 없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을
								받습니다.
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제14조 (위치기반서비스의 내용)</h4>
						<ul class="pl-0">
							<li>
								회사는 다음과 같이 위치정보를 수집합니다.
								<ol class="pl-0">
									<li>휴대폰 등 모바일기기가 사용하는 이동통신사업자 기지국 기반의 실시간 위치정보 수집</li>
									<li>GPS가 포함된 모바일기기의 GPS정보를 통한 위치정보 수집</li>
									<li>인터넷망을 통해 접속 시 인터넷서비스사업자로부터 제공받는 위치정보 수집</li>
									<li>회사는 제공받은 위치정보, 상태정보를 이용하여 다음의 내용을 서비스 합니다.</li>
								</ol>
							</li>
						</ul>
						<v-col
							cols="12"
							class="table_type01"
						>
							<v-row>
								<v-col
									cols="4"
									class="th"
									>서비스 내용</v-col
								>
								<v-col
									cols="4"
									class="th"
									>서비스 대상</v-col
								>
								<v-col
									cols="4"
									class="th"
									>비고</v-col
								>
							</v-row>
							<v-row>
								<v-col
									cols="4"
									class="td"
									>주변 인테리어점 찾기/현 위치로 인테리어점 검색(인근 인테리어점 정보 제공)</v-col
								>
								<v-col
									cols="4"
									class="td"
									>인테리어점 찾기 견적상담 신청</v-col
								>
								<v-col
									cols="4"
									class="td"
									>회원 및 비회원</v-col
								>
							</v-row>
						</v-col>

						<h4 class="mt-4 mb-2">제15조 (별정통신 서비스에 관한 공지사항)</h4>
						<ul class="pl-0">
							<li>
								회사 서비스 중 사업자회원(이하 “인테리어점”) 전화연결에 사용되는 050 비즈콜 서비스는 델피콤㈜가
								회사와의 제휴를 통하여 제공합니다.
							</li>
							<li>
								이용자와 인테리어점이 050 비즈콜 서비스 이용 시 녹취되며, 녹취파일 청취는 분쟁발생시 해결을 위한
								확인용으로만 사용합니다.
							</li>
						</ul>
					</div>

					<h3>제 4 장 계약의 해지 및 서비스 이용 제한</h3>
					<div class="mb-8">
						<h4 class="mt-4 mb-2">제16조 (계약의 헤지)</h4>
						<ul class="pl-0">
							<li>
								회원이 서비스 이용계약을 해지하고자 할 경우에는 본인이 사이트 상에서 또는 회사가 정한 별도의
								이용방법으로 회사에 해지신청을 하여야 합니다.
							</li>
							<li>
								전항의 경우 회원이 이름, , ID, 비밀번호를 입력하여 본인임을 확인한 후에 회원탈퇴 확인을 클릭하면
								자동으로 회원가입 및 서비스 이용계약이 해지됩니다.
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제17조 (서비스 이용 제한)</h4>
						<ul class="pl-0">
							<li>
								회사는 이용자가 아래 사항에 해당하는 행위를 하였을 경우, 사전 통지 없이 이용계약을 해지하거나
								기간을 정하여 서비스 이용을 중지할 수 있습니다.
								<ol class="pl-0">
									<li>공공 질서 및 미풍 양속에 저해되는 행위를 한 경우</li>
									<li>범죄적 행위에 관련되는 경우</li>
									<li>국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우</li>
									<li>타인의 ID 및 비밀번호를 도용한 경우</li>
									<li>타인의 명예를 손상시키거나 불이익을 주는 경우</li>
									<li>같은 사용자가 다른 ID로 이중 등록을 한 경우</li>
									<li>저작권 등 기타 타인의 권리를 침해하는 내용인 경우</li>
									<li>
										해킹, 컴퓨터 바이러스 유포 등 서비스에 위해를 가하여 서비스의 건전한 이용을 저해하는 경우
									</li>
									<li>기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우</li>
								</ol>
							</li>
							<li>
								회사가 회원의 이용계약을 해지하거나 서비스를 중지할 경우 회원에게 해지 전에 이를 통지합니다. 다만,
								긴급하게 서비스의 이용을 중지해야 할 필요가 있는 경우에는 사전 통지 없이 서비스를 중지할 수
								있습니다.
							</li>
							<li>
								전항의 규정에 의하여 서비스 이용 중지를 통지 받은 회원은 서비스 이용 중지에 대하여 이의신청을 할
								수 있습니다.
							</li>
							<li>
								회사는 서비스 이용 중지 기간 중에 그 이용 중지 사유가 해소된 것이 확인되면 이용자의 이용 중지를
								즉시 해제합니다.
							</li>
						</ul>
					</div>

					<h3>제 5 장 손해배상</h3>
					<div class="mb-8">
						<h4 class="mt-4 mb-2">제18조 (손해 배상)</h4>
						<ul class="pl-0">
							<li>
								회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한
								회원은 회사에 발생하는 모든 손해를 배상하여야 합니다.
							</li>
							<li>
								회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의
								제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과
								비용으로 회사를 면책 시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한
								모든 손해를 배상하여야 합니다.
							</li>
							<li>
								회사는 이용자와 인테리어점간의 중개 서비스를 제공할 뿐이므로, 이용자가 인테리어점으로부터 구매한
								재화 등의 품질이나 인테리어점의 신뢰도에 대해서는 보증하지 않습니다.
							</li>
							<li>
								회사는 이용자가 인테리어점으로부터 구매한 재화 등에 대해 보증하거나 별도의 책임을 지지 않으며,
								회사의 귀책사유가 없는 한 재화 등과 관련한 일체의 책임은 인테리어점에게 있습니다.
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제19조 (면책 조항)</h4>
						<ul class="pl-0">
							<li>
								회사는 이용자가 사이트에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 내용에 관하여는 어떠한 책임도
								지지 않습니다.
							</li>
							<li>
								회사는 이용자가 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지
								않으며, 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.
							</li>
							<li>
								회사는 회원 상호간 또는 회원과 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할
								의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
							</li>
							<li>회사는 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다.</li>
							<li>
								회사는 천재지변 또는 이에 준하는 통제할 수 없는 사유로 인해 서비스를 제공할 수 없는 경우에는
								서비스 제공에 대한 책임이 면제됩니다
							</li>
						</ul>

						<h4 class="mt-4 mb-2">제20조 (관할 법원)</h4>
						<ul class="pl-0">
							<li>
								서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해
								성실히 협의합니다.
							</li>
							<li>
								전항에도 불구하고 회사와 이용자간의 분쟁이 해결되지 않을 경우, 양 당사자는 민사소송법상의
								관할법원에 소를 제기할 수 있습니다.
							</li>
						</ul>

						<p class="mt-10">(부칙) 이 약관은 2022년 3월 31일부터 시행합니다.</p>
					</div>
				</v-col>
			</v-col>
		</v-container>
	</div>
</template>

<script>
export default {
	name: 'SiteTerms',
	metaInfo: {
		title: '이용약관ㅣ동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '이용약관ㅣ동화자연마루',
			},
			{
				vmid: 'keywords',
				property: 'keywords',
				content: '동화자연마루 이용약관',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '동화자연마루의 이용약관입니다.',
			},
			{
				vmid: 'url',
				property: 'og:url',
				content: `${process.env.VUE_APP_BASIC_SERVER}/site/terms`,
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	components: {},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.terms {
	font-size: 15px !important;
	color: #262626 !important;
	line-height: 1.5 !important;
	word-break: keep-all !important;

	h3 {
		font-size: 16px;
		font-family: 'NotoSansKR-Regular' !important;
	}

	h4 {
		font-size: 15px;
		font-family: 'NotoSansKR-Regular' !important;
		color: #42883d !important;
	}

	p {
		font-family: 'NotoSansKR-Regular' !important;
	}

	ol {
		li {
			position: relative;
			padding-left: 10px;
			padding-bottom: 3px;
			font-size: 12px;
			color: $color_gray_6;
			&::before {
				display: block;
				position: absolute;
				left: 0;
				clear: both;
				content: '-';
			}
		}
	}

	ul {
		counter-reset: var-count !important;
		& > li {
			position: relative;
			padding: 0 0 3px 20px !important;
			font-family: 'NotoSansKR-Regular' !important;
			list-style: none !important;
			text-align: start !important;
		}
		& > li::before {
			width: 15px;
			height: 15px;
			position: absolute;
			top: 4px;
			left: 0;
			font-size: 13px;
			counter-increment: var-count;
			content: counter(var-count);
			border-radius: 50%;
			border: 1px solid $color_gray_6;
			text-align: center;
			line-height: 12px;
		}
	}
}
</style>
